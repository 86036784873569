import { useEffect, useState } from "react";
import { accountService, cookieService } from "../../../../services";
import { textUtils } from "../../../../utils/textUtils";

export function useAccount () {

  const [accountDetails, setAccountDetails] = useState();
  const [isGettingAccountDetails, setIsGettingAccountDetails] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActivatingPurchase, setIsActivatingPurchase] = useState(false);
  const [email, setEmail] = useState('');

  const getAccountDetails = async () => {
    const hash = cookieService.getHashFromCookie();

    if (!hash) {
      console.log('User not logged in')
      return;
    }

    // Otherwise, let's attempt to get the details
    setIsGettingAccountDetails(true);

    let data;
    try {
      data = await accountService.getAccountDetails(hash);
    } catch (err) {
      console.log(err);
    }

    if (data.value.type === "GetAccountSuccess") {
      setAccountDetails(data.value.data);
    } else if (data.value.type === 'HashAndTeamNotFound') {
      setErrorMessage('Account not found. Are you authenticated?')
    }

    setIsGettingAccountDetails(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }

  const submitActivatePurchase = async () => {
    const hash = cookieService.getHashFromCookie();

    // Validate the form
    if (!!email === false) {
      return alert('Howdy, looks like you missed the email!')
    }

    if (textUtils.validateEmail(email) === false) {
      return alert('Looks like that email isnt quite valid. Want to try that again?')
    }

    // If the form is valid, start the request
    setIsActivatingPurchase(true);

    try {
      await accountService.assignBookToTeamMember(hash, email);

      let firstIndexOfInactiveHash = accountDetails.team.hashes.findIndex((h) => !h.active);

      // Update account
      setAccountDetails({
        ...accountDetails,
        team: {
          ...accountDetails.team,
          hashes: accountDetails.team.hashes.map((hash, index) => index === firstIndexOfInactiveHash ? {
            ...hash,
            active: true,
            email
          } : hash)
        }
      })

      alert(`Done! Sent an email to ${email} with instructions.`);
      setEmail('');
      closeModal();
    } catch (err) {

      console.log(err.response.data)
      
      if (err.response.data.type === 'ClientError') {
        setErrorMessage(err.response.data.error);
      }

      if (err.response.data.type === 'TeamNotFound') {
        setErrorMessage(`Team not found error: that's strange!`);
      }

      if (err.response.data.type === 'TeamMemberAlreadyAssigned') {
        setErrorMessage(`This email address has already been assigned a copy.`);
      }

      if (err.response.data.type === 'NoMoreAvailableBooks') {
        setErrorMessage(`You don't have any more books to assign. To purchase more copies, visit solidbook.io.`);
      }
    }
    
    setIsActivatingPurchase(false);
  }

  const updateFormField = (value) => {
    setEmail(value);
    setErrorMessage('');
  }

  useEffect(() => {
    getAccountDetails();
  }, [])

  return {
    models: {
      accountDetails,
      isGettingAccountDetails,
      errorMessage,
      isModalOpen,
      isActivatingPurchase
    },
    operations: {
      openModal,
      closeModal,
      submitActivatePurchase,
      updateFormField
    }
  }
}