
import React from 'react';
import { useAccount } from '../hocs/useAccount';
import "../styles/AccountPage.sass"
import ActivatePurchaseModal from './ActivatePurchaseModal';

const isIndividualAccount = (accountDetails) => {
  return accountDetails.accountType === 'individual';
}

const wasGiftedCopy = (accountDetails) => {
  return isIndividualAccount(accountDetails) && accountDetails.individual.ownerEmail !== undefined;
}

const isTeamAccount = (accountDetails) => {
  return accountDetails.accountType === 'team';
}

const countTotalPurchases = (accountDetails) => {
  return accountDetails.team.hashes.length
}

const countAllocatedPurchases = (accountDetails) => {
  return accountDetails.team.hashes.filter((h) => h.active).length;
}

const canAllocatePurchase = (accountDetails) => {
  return accountDetails.team.hashes.findIndex((h) => !h.active) !== -1;
}

const AccountPage = () => {
  const { models, operations } = useAccount();

  return (
    <div className="account-page">
      <h1>Account</h1>
      
      <div>{models.errorMessage}</div>
      { models.isGettingAccountDetails ? <div>Loading...</div> : '' }
      { !models.accountDetails ? '' : (
          <>
            <h2>Purchases</h2>
            <div className="purchases">

              {isIndividualAccount(models.accountDetails) && (
                <>
                  {<div><b>Owner:</b> This copy is owned by ({models.accountDetails.individual.email})</div>}
                  <br/>
                  {wasGiftedCopy(models.accountDetails) && <div><b>Gift:</b> Access was given to you by ({ models.accountDetails.individual.ownerEmail })</div>}
                  <br/>
                  {/* <div><b>Gift to a friend</b>: To gift a copy to a friend or coworker, purchase additional copies from <a href="https://solidbook.io">solidbook.io</a> using the same email address ({models.accountDetails.individual.email}) and then activate them here.</div> */}
                </>
              )}
              {isTeamAccount(models.accountDetails) && (
                <>
                <p>{countAllocatedPurchases(models.accountDetails)} of your {countTotalPurchases(models.accountDetails)} copies activated.</p>
                  {models.accountDetails.team.hashes.map((h, index) => (
                  <div key={index} className="account-purchase">
                    <div className="index">{index + 1}. </div>
                    {h.active && <div className="email">{h.email}</div>}
                    <div className={`status ${h.active ? 'active' : 'inactive'}`}>{h.active ? 'active' : 'not yet activated'}</div>
                  </div>
                ))}

                <br/>
                {canAllocatePurchase(models.accountDetails) && <div className="open-modal-button" onClick={() => operations.openModal()}>Activate a purchase</div>}
                {!canAllocatePurchase(models.accountDetails) && <div>
                    <b>Get more copies</b>: To send copies to friends or teammates, purchase them from <a href="https://solidbook.io">solidbook.io</a> using the same email address ({models.accountDetails.team.ownerEmail}) and then activate them here.</div>}
                <ActivatePurchaseModal
                  isOpen={models.isModalOpen}
                  onClose={() => operations.closeModal()}
                  loading={models.isActivatingPurchase}
                  onUpdateFormField={(val) => operations.updateFormField(val)} 
                  onSubmit={() => operations.submitActivatePurchase()}
                  errorMessage={models.errorMessage}
                />
                </>
              )}
            </div>
          </>
      )}
    </div>
  )
}

export default AccountPage;