
import React from 'react'
import Button from '../../button/components/Button';
import Input from '../../input/components/Input';
import Modal from '../../modal/Modal'

function ActivatePurchaseModal (props) {
  const { isOpen, onClose, loading, onUpdateFormField, onSubmit, errorMessage } = props;

  return (
    <Modal
      isOpen={isOpen}
      loading={loading}
      onClose={() => onClose()}
    >
      <div className="activate-purchase-modal" style={{ textAlign: 'center', maxWidth: '400px'}}>
        <h3>Activate a purchase</h3>
        <p>To activate a purchase, enter the email address of the new owner. You can't change this later.</p>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <Input placeholder="Enter an email here" onChange={(e) => onUpdateFormField(e.target.value)}/>
        <Button
          text="Activate"
          onClick={() => onSubmit()}
        />
      </div>
    </Modal>
  )
}

export default ActivatePurchaseModal;