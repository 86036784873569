import React from 'react'
import Layout from '../components/layout/components/Layout';
import AccountPage from '../components/shared/account/components/AccountPage';
import Loading from '../components/shared/loading/components/Loading';
import NotAuthenticated from '../components/shared/notAuthenticated/components/NotAuthenticated';
import { useAuth } from "../services/AuthProvider"

const Account = () => {
  const { isAuthenticated, isCheckingAuthState } = useAuth();
  return (
    <Layout>
      {isCheckingAuthState ? (
        <Loading/>
      ) : isAuthenticated ? (
        <AccountPage/>
      ) : (
        <NotAuthenticated/>
      )}
    </Layout>
  )
}



export default Account;